import { inject, observer } from "mobx-react";
import { useState, useRef, useEffect, useCallback } from "react";
import Loader from "../../../Components/Loader";
import styled from "styled-components";
import { saveText, updateHistory } from "../api.service.v2";
import {
  createThread,
  addMessage,
  runAssistant,
  fetchMessages,
  addFileToThread,
} from "../azureApiService";
import {
  PaperClipIcon,
  ArrowRightIcon,
  ClipboardListIcon,
  BookOpenIcon,
  ChatAltIcon,
  AcademicCapIcon,
  AdjustmentsIcon,
  BeakerIcon,
  XIcon,
  MicrophoneIcon,
} from "@heroicons/react/solid";
import ToolModal from "./ToolModal";
import AskQuestionModal from "./AskQuestionModal";
import formatGuidance from "./formatGuidance";
import {
  progressNoteInstructions,
  hpAssessmentInstructions,
} from "./medicalNoteInstructions";
import RecordingComponent from "./RecordingComponent";

const InteractionPanel = inject("navixScribeV2Store")(
  observer(({ navixScribeV2Store }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isAskQuestionModalOpen, setIsAskQuestionModalOpen] = useState(false);
    const [isMedicalNoteModalOpen, setIsMedicalNoteModalOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const panelRef = useRef(null);
    const fileInputRef = useRef(null);
    const textAreaRef = useRef(null);
    const containerRef = useRef(null);

    const handleOnChangeEvent = (e) => {
      const { value } = e.target;
      setMessage(value);
    };

    const supportedExtensions = [
      "c",
      "cpp",
      "cs",
      "css",
      "doc",
      "docx",
      "html",
      "java",
      "js",
      "json",
      "md",
      "pdf",
      "php",
      "pptx",
      "py",
      "rb",
      "sh",
      "tex",
      "ts",
      "txt",
    ];

    const handleFileChangeEvent = (e) => {
      const file = e.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (supportedExtensions.includes(fileExtension)) {
          setUploadedFile(file);
          console.log("File uploaded:", file.name);
        } else {
          alert("Unsupported file type. Please upload a supported file.");
          e.target.value = null;
        }
      }
    };

    const createNewThreadWithSaveText = async (initialMessage = "") => {
      try {
        const response = await saveText(initialMessage);
        if (response.data.history && response.data.history._id) {
          const history = response.data.history;
          let newThreadId = history.threadId;

          if (!newThreadId) {
            const newThread = await createThread();
            newThreadId = newThread.thread_id;
            await updateHistory(history._id, { threadId: newThreadId });
          }

          navixScribeV2Store.setSelectedThread(newThreadId);

          return newThreadId;
        }
      } catch (error) {
        console.error("Error creating new thread:", error);
      }
      return null;
    };

    const isToolDisabled = (button) => {
      const currentThreadId = navixScribeV2Store.selectedThread;
      return !currentThreadId && !uploadedFile;
    };

    const handleTextSubmit = async () => {
      try {
        setLoading(true);
        let currentThreadId = navixScribeV2Store.selectedThread;

        if (!currentThreadId) {
          let initialMessage = "";
          if (uploadedFile) {
            initialMessage = `File upload: ${uploadedFile.name}`;
          } else if (message) {
            initialMessage = message;
          }
          currentThreadId = await createNewThreadWithSaveText(initialMessage);
        }

        if (currentThreadId) {
          if (message) {
            await addMessage(currentThreadId, "user", message);
          }

          if (uploadedFile) {
            await addFileToThread(currentThreadId, uploadedFile);
          }

          await runAssistant(
            currentThreadId,
            "asst_HagPjnY7H8EYT5gRm8o8DSsX",
            ""
          );
          const fetchedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(fetchedMessages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setMessage("");
        setUploadedFile(null);
        setLoading(false);
      }
    };

    const handleFileUpload = async (threadId) => {
      if (uploadedFile) {
        await addFileToThread(threadId, uploadedFile);
        setUploadedFile(null);
      }
    };

    const handleConfirmAndRunAssistant = async (assistantId, format) => {
      try {
        setLoading(true);
        let currentThreadId = navixScribeV2Store.selectedThread;

        if (!currentThreadId) {
          const initialMessage = uploadedFile
            ? `Create ${format} clinical note of attached file`
            : `Create ${format} clinical note`;
          currentThreadId = await createNewThreadWithSaveText(initialMessage);
        }

        if (currentThreadId) {
          await handleFileUpload(currentThreadId);
          await handleRunAssistant(
            assistantId,
            `Generate a ${format} note.`,
            currentThreadId,
            format
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsModalOpen(false);
      }
    };

    const handleRunAssistant = async (
      assistantId,
      message,
      threadId,
      format = ""
    ) => {
      if (loading) return;
      try {
        setLoading(true);
        let instructions = "";
        if (format && formatGuidance[format]) {
          instructions = formatGuidance[format];
        }

        await addMessage(threadId, "user", message);
        await runAssistant(threadId, assistantId, instructions);
        const updatedMessages = await fetchMessages(threadId);
        navixScribeV2Store.setMessages(updatedMessages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const handleRemoveFile = () => {
      setUploadedFile(null);
    };

    const handleAskQuestion = async (question) => {
      try {
        setLoading(true);
        setIsAskQuestionModalOpen(false);
        let currentThreadId = navixScribeV2Store.selectedThread;

        if (!currentThreadId) {
          const initialMessage = uploadedFile
            ? "Ask a question about attached file"
            : "Ask a question";
          currentThreadId = await createNewThreadWithSaveText(initialMessage);
        }

        if (currentThreadId) {
          await handleFileUpload(currentThreadId);
          await addMessage(currentThreadId, "user", question);
          await runAssistant(
            currentThreadId,
            "asst_f2zVx6gLotQaJwVlHPBYfkEX",
            ""
          );
          const updatedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(updatedMessages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const isSubmitButtonDisabled = () => {
      return (!message && !uploadedFile) || loading;
    };

    const handleAttachClick = () => {
      fileInputRef.current.click();
    };

    const handleToolClick = async (button) => {
      try {
        if (button.label === "Ask a Question") {
          setIsAskQuestionModalOpen(true);
        } else if (button.label === "Medical Notes") {
          setIsMedicalNoteModalOpen(true);
        } else if (button.label === "Clinical Note") {
          setIsModalOpen(true);
        } else {
          setLoading(true);
          let currentThreadId = navixScribeV2Store.selectedThread;

          if (!currentThreadId) {
            let initialMessage = "";
            if (uploadedFile) {
              switch (button.label) {
                case "Create Summary":
                  initialMessage = "Create summary of attached file";
                  break;
                case "Coder":
                  initialMessage = "Run code analysis of attached file";
                  break;
                case "Diagnostics":
                  initialMessage = "Run diagnostics of attached file";
                  break;
                default:
                  initialMessage = "Analyze attached file";
              }
            }
            currentThreadId = await createNewThreadWithSaveText(initialMessage);
          }

          if (currentThreadId) {
            await handleFileUpload(currentThreadId);
            if (button.assistantId) {
              await handleRunAssistant(
                button.assistantId,
                button.message,
                currentThreadId
              );
            }
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    useEffect(() => {
      if (textAreaRef.current && containerRef.current) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.maxHeight = "30px";
        containerRef.current.style.height = "50px";

        if (textAreaRef.current.scrollHeight > 30) {
          const newHeight = Math.min(textAreaRef.current.scrollHeight, 120);
          textAreaRef.current.style.maxHeight = "120px";
          textAreaRef.current.style.height = `${newHeight}px`;
          containerRef.current.style.height = `${newHeight + 20}px`;
        }
      }
    }, [message]);

    const buttons = [
      {
        label: "Clinical Note",
        icon: <ClipboardListIcon className="icon" />,
        assistantId: "asst_nEalig2Xy5NtcDWDqOzdHZJr",
        showModal: true,
        message: "Generate a clinical note.",
      },
      {
        label: "Medical Notes",
        icon: <BookOpenIcon className="icon" />,
        assistantId: "asst_jHdxpnBQ8YyJYiDCjG2mcjxk",
        showModal: true,
        message: "Generate medical notes.",
      },
      {
        label: "Ask a Question",
        icon: <ChatAltIcon className="icon" />,
        assistantId: "asst_f2zVx6gLotQaJwVlHPBYfkEX",
        showModal: true,
        message: "Ask a question.",
      },
      {
        label: "Create Summary",
        icon: <AcademicCapIcon className="icon" />,
        assistantId: "asst_c8v8h2FGr4aQuLVrAn9wCxTo",
        showModal: false,
        message: "Create a summary.",
      },
      {
        label: "Coder",
        icon: <AdjustmentsIcon className="icon" />,
        assistantId: "asst_7OHxzqLKthcN04ErEMgVe3m1",
        showModal: false,
        message: "Run code analysis.",
      },
      {
        label: "Diagnostics",
        icon: <BeakerIcon className="icon" />,
        assistantId: "asst_YS3mhG6GZ7CLVJPbYATxmqrJ",
        showModal: false,
        message: "Run diagnostics.",
      },
    ];

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleTextSubmit();
      }
    };

    const handleDragOver = useCallback((e) => {
      e.preventDefault();
    }, []);

    const handleDragEnter = useCallback((e) => {
      e.preventDefault();
      setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
      e.preventDefault();
      if (!panelRef.current.contains(e.relatedTarget)) {
        setIsDragging(false);
      }
    }, []);

    const handleDrop = useCallback(
      (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          if (supportedExtensions.includes(fileExtension)) {
            setUploadedFile(file);
            console.log("File uploaded:", file.name);
          } else {
            alert("Unsupported file type. Please upload a supported file.");
          }
        }
      },
      [supportedExtensions]
    );

    useEffect(() => {
      const panel = panelRef.current;
      if (panel) {
        panel.addEventListener("dragenter", handleDragEnter);
        panel.addEventListener("dragleave", handleDragLeave);
        panel.addEventListener("dragover", handleDragOver);
        panel.addEventListener("drop", handleDrop);
        return () => {
          panel.removeEventListener("dragenter", handleDragEnter);
          panel.removeEventListener("dragleave", handleDragLeave);
          panel.removeEventListener("dragover", handleDragOver);
          panel.removeEventListener("drop", handleDrop);
        };
      }
    }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

    const handleConfirmAndRunMedicalNote = async (noteType) => {
      try {
        setLoading(true);
        let currentThreadId = navixScribeV2Store.selectedThread;

        if (!currentThreadId) {
          const initialMessage = uploadedFile
            ? `Create ${noteType} of attached file`
            : `Create ${noteType}`;
          currentThreadId = await createNewThreadWithSaveText(initialMessage);
        }

        if (currentThreadId) {
          await handleFileUpload(currentThreadId);
          const instructions =
            noteType === "Progress Note"
              ? progressNoteInstructions
              : hpAssessmentInstructions;
          await addMessage(currentThreadId, "user", `Generate a ${noteType}.`);
          await runAssistant(
            currentThreadId,
            "asst_jHdxpnBQ8YyJYiDCjG2mcjxk",
            instructions
          );
          const updatedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(updatedMessages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsMedicalNoteModalOpen(false);
      }
    };

    const handleCloseRecordingModal = () => {
      setIsRecording(false);
    };

    return (
      <InteractionPanelContainer ref={panelRef} isDragging={isDragging}>
        {navixScribeV2Store.selectedThread && (
          <RecordEncounterButton onClick={() => setIsRecording(true)}>
            <MicrophoneIcon className="icon" />
            <span>Record Encounter</span>
          </RecordEncounterButton>
        )}

        {isRecording && (
          <ModalOverlay onClick={handleCloseRecordingModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <CloseButton onClick={handleCloseRecordingModal}>
                <XIcon className="icon" />
              </CloseButton>
              <RecordingComponent
                navixScribeV2Store={navixScribeV2Store}
                onClose={handleCloseRecordingModal}
              />
            </ModalContent>
          </ModalOverlay>
        )}

        <CenteredButtonGroup>
          {buttons.map((button, index) => (
            <ToolButton
              key={index}
              onClick={() => handleToolClick(button)}
              disabled={isToolDisabled(button)}
            >
              {button.icon}
              <span>{button.label}</span>
            </ToolButton>
          ))}
        </CenteredButtonGroup>

        <TextInputContainer ref={containerRef}>
          <TextInput
            ref={textAreaRef}
            placeholder="Have a conversation with your Encounter"
            value={message}
            onChange={handleOnChangeEvent}
            onKeyDown={handleKeyDown}
          />
          {uploadedFile && (
            <UploadedFileLabel>
              {uploadedFile.name}
              <RemoveFileButton onClick={handleRemoveFile}>
                <XIcon className="icon" />
              </RemoveFileButton>
            </UploadedFileLabel>
          )}
          <AttachButton onClick={handleAttachClick}>
            <PaperClipIcon className="icon" />
          </AttachButton>
          <input
            type="file"
            onChange={handleFileChangeEvent}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <SubmitButton
            onClick={handleTextSubmit}
            disabled={isSubmitButtonDisabled()}
          >
            {loading ? (
              <Loader active={loading} className="w-6 h-6 mr-1" />
            ) : (
              <ArrowRightIcon className="icon" />
            )}
          </SubmitButton>
        </TextInputContainer>

        <ToolModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Create Clinical Note"
          options={["SOAP", "DAP", "SIRP", "BIRP", "GIRP", "GROUP"]}
          onGenerate={(format) =>
            handleConfirmAndRunAssistant(
              "asst_nEalig2Xy5NtcDWDqOzdHZJr",
              format
            )
          }
        />

        <ToolModal
          isOpen={isMedicalNoteModalOpen}
          onClose={() => setIsMedicalNoteModalOpen(false)}
          title="Create Medical Note"
          options={["Progress Note", "H&P Assessment"]}
          onGenerate={handleConfirmAndRunMedicalNote}
        />

        <AskQuestionModal
          isOpen={isAskQuestionModalOpen}
          onClose={() => setIsAskQuestionModalOpen(false)}
          onConfirm={(question) => {
            setIsAskQuestionModalOpen(false);
            handleAskQuestion(question);
          }}
        />
      </InteractionPanelContainer>
    );
  })
);

const InteractionPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: relative;
  transition: all 0.3s ease;

  ${({ isDragging }) =>
    isDragging &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(76, 29, 149, 0.1);
      border: 2px dashed #4c1d95;
      border-radius: 8px;
      z-index: 10;
    }
  `}
`;

const RecordEncounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  position: absolute;
  top: -50%;
  right: 45%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Nicer box shadow */
  cursor: pointer;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;

  .icon {
    width: 20px;
    height: 20px;
    color: #44515a;
    margin-right: 10px;
  }

  span {
    color: #44515a;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced hover effect */
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 30px;
  position: absolute;
  left: 31%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
    color: #333;
  }
`;

const CenteredButtonGroup = styled.div`
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 20px; /* Space between buttons */
  width: 100%;
  margin-bottom: 10px;
`;

const ToolButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border: 1px solid #5c4bdb; /* Violet border */
  border-radius: 16px;
  background: #fff;
  transition: all 0.3s ease;
  width: 172px; /* Adjusted to match the Figma dimensions */
  height: 113px; /* Adjusted to match the Figma dimensions */
  position: relative; /* Ensures absolute positioning works */

  .icon {
    width: 24px;
    height: 24px;
    color: #4336ab;
    align-self: flex-start; /* Align the icon to the left */
    position: absolute;
    top: 23px; /* Adjusted to match the Figma spacing */
    left: 35px; /* Adjusted to match the Figma spacing */
  }

  span {
    color: #8b8b8e; /* Adjusted text color to match Figma */
    font-size: 16px; /* Base font size */
    font-family: "SF Pro Display", sans-serif; /* Matching Figma font */
    font-weight: 500; /* Medium weight */
    margin-top: 30px; /* Add margin to move the text down */
  }

  &:hover {
    background-color: #f3f4f6;
    border-color: #7c3aed;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    border-color: #e0e0e0;
  }

  &:disabled:hover {
    background-color: #f8f8f8;
    box-shadow: none;
  }

  &:disabled .icon {
  }

  &:disabled span {
    color: #a0a0a0;
  }
`;

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  gap: 0.5rem;
  position: relative;
  height: 50px;
  transition: height 0.3s ease;
`;

const TextInput = styled.textarea`
  flex: 1;
  border: none;
  background: transparent;
  padding: 7px 0 7px 15px;
  font-size: 1rem;
  outline: none;
  resize: none;
  transition: height 0.3s ease;
  overflow-y: auto;
`;

const SubmitButton = styled.button`
  background-color: #4c1d95;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;

  .icon {
    width: 20px;
    height: 20px;
    color: white;
  }

  &:hover {
    background-color: #c4b2ff;
  }

  &:disabled {
    background-color: #e0d7ff;
    cursor: not-allowed;
  }
`;

const UploadedFileLabel = styled.div`
  position: relative;
  margin-top: 5px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.8em;
  color: #333;
  display: inline-flex;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    padding-right: 25px;
  }
`;

const RemoveFileButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${UploadedFileLabel}:hover & {
    opacity: 1;
  }

  .icon {
    width: 12px;
    height: 12px;
    color: #666;
  }

  &:hover .icon {
    color: #333;
  }
`;

const AttachButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #f0f0f0; /* Adjust background color as needed */
  transition: background-color 0.3s;

  .icon {
    width: 24px;
    height: 24px;
    color: #888888; /* Adjust icon color as needed */
  }

  &:hover {
    background-color: #e0e0e0; /* Adjust hover background color as needed */
  }
`;

export default InteractionPanel;
